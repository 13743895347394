/* src/Skills.css */
.skills-container {
    text-align: center;
    padding: 20px;
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .skill-item {
    position: relative; /* Required for positioning the tooltip */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .skill-item p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  /* Tooltip Container */
  .skill-experience {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the skill icon */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    width: 150px; /* Adjust the width as needed */
    font-size: 12px; /* Smaller text size */
    pointer-events: none; /* Prevents the tooltip from blocking mouse events */
  }
  
  /* Show Tooltip on Hover */
  .skill-item:hover .skill-experience {
    visibility: visible;
    opacity: 1;
  }
  
  /* Hover Effect */
  .skill-item:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .skill-item svg,
  .skill-item img {
    transition: color 0.3s, transform 0.3s;
  }
  
  .skill-item:hover svg,
  .skill-item:hover img {
    color: #007bff; /* Change color on hover */
  }
  